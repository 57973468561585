import { PluginRef } from 'gatsby';
import { URLS } from './config-urls';
import { stripHtmlStrings } from './src/shared/stripHTMLStrings';

interface SiteConfig {
    id: string;
    title: string;
    siteUrl: string;
    endpoint: string;
    menus: string[];
    plugins: PluginRef[];
    fragments: any[];
    siteTitleSuffix: string;
    robotsConfig: {
        host: string;
        sitemap: string | string[];
        policy: [
            {
                userAgent?: string;
                allow?: string[] | string;
                disallow?: string[] | string;
            }
        ];
    };
}

const baseMenus = [`sites`, `main`, `footer-spectrum-site`, `footer`];

const createArticleSearchBody = (init: string, fieldComponents: any[]) => {
    if (!fieldComponents) return init;
    const body = [init];
    const paragraphs = {
        paragraph__article_body: (comp) =>
            body.push(stripHtmlStrings(comp.field_body?.value)),
        paragraph__paragraph_header: (comp) => {
            body.push(
                `${(
                    (comp.field_numbering ? comp.field_numbering : ``) +
                    ` ` +
                    comp.field_heading
                ).trim()}`
            );
            if (comp.field_subtitle) {
                body.push(comp.field_subtitle);
            }
        },
        paragraph__image: (comp) => {
            body.push(comp.relationships?.field_image?.field_media_image?.alt);
            if (comp.field_subtitle) {
                body.push(comp.field_subtitle);
            }
        },
        paragraph__pull_quote_article: (comp) => {
            body.push(stripHtmlStrings(comp.field_body?.value));
            if (comp.field_subtitle) {
                body.push(comp.field_subtitle);
            }
            if (comp.relationships?.field_image?.field_media_image?.alt) {
                body.push(
                    comp.relationships?.field_image?.field_media_image?.alt
                );
            }
        },
        paragraph__video: (comp) => {
            body.push(comp.field_component_title);
            if (comp.field_caption) {
                body.push(comp.field_caption);
            }
        },
    };
    fieldComponents.forEach((comp) => {
        if (paragraphs[comp.type]) paragraphs[comp.type](comp);
    });

    return body.join(` | `);
};

const SITES: { [id: string]: SiteConfig } = {
    cew: {
        fragments: [],
        id: `cew`,
        title: `Charter Corporate Website`,
        siteUrl: `https://${URLS[process.env.GATSBY_SITE_NAME]?.prod[0]}`,
        endpoint: process.env.ENDPOINT_CEW
            ? process.env.ENDPOINT_CEW
            : `https://prod-cew.corporate.charter.com`,
        menus: baseMenus,
        siteTitleSuffix: ` | Charter Communications`,
        robotsConfig: {
            host: `https://corporate.charter.com`,
            sitemap: [
                `https://corporate.charter.com/sitemap/sitemap-index.xml`,
                `https://corporate.charter.com/image-sitemap.xml`,
            ],
            policy: [
                {
                    userAgent: `*`,
                    disallow: [
                        `/newsroom?*`,
                        `/sites/default/files/2022-09/Charter-2021-ESG-Report.pdf`,
                        `/esg-report.pdf`,
                        `/search?*`,
                    ],
                    allow: `/newsroom?start=*`,
                },
            ],
        },

        plugins: [
            {
                resolve: `gatsby-plugin-local-search`,
                options: {
                    query: `
                    {
                        allGenericPage(sort: {changed: DESC}) {
                            nodes {
                                drupal_internal__nid
                                title
                                body
                                changed
                                internal {
                                    type
                                }
                                fields {
                                    url
                                }
                                subtitle
                            }
                        }
                        allNodeNewsroomArticle(sort: {changed: DESC}) {
                            nodes {
                                drupal_internal__nid
                                field_news_date(formatString: "MMMM D, YYYY")
                                title
                                changed
                                internal {
                                    type
                                }
                                fields {
                                    url
                                }
                                field_summary
                                field_subtitle
                                relationships {
                                    field_news_category {
                                        name
                                    }
                                    field_components {
                                        type: __typename
                                        ... on paragraph__article_body {
                                            field_body {
                                                value
                                            }
                                        }
                                        ... on paragraph__video {
                                            field_component_title
                                            field_caption
                                        }
                                        ... on paragraph__image {
                                            field_subtitle
                                            relationships {
                                                field_image {
                                                    field_media_image {
                                                        alt
                                                    }
                                                }
                                            }
                                        }
                                        ... on paragraph__pull_quote_article {
                                            field_subtitle
                                            field_body {
                                                value
                                            }
                                            relationships {
                                                field_image {
                                                    field_media_image {
                                                        alt
                                                    }
                                                }
                                            }
                                        }
                                        ... on paragraph__paragraph_header {
                                            field_heading
                                            field_numbering
                                            field_subtitle
                                        }
                                    }
                                }
                            }
                        }
                        allNodeIrNewsArticle(sort: {changed: DESC}) {
                            nodes {
                                drupal_internal__nid
                                field_news_date(formatString: "MMMM D, YYYY")
                                title
                                changed
                                fields {
                                    url
                                }
                                field_summary
                                internal {
                                    type
                                }
                                relationships {
                                    field_news_category {
                                        name
                                    }
                                }
                            }
                        }
                        allNodeLeadership(sort: {changed: DESC}) {
                            nodes {
                                drupal_internal__nid
                                title
                                changed
                                internal {
                                    type
                                }
                                fields {
                                    url
                                }
                                field_leadership
                                field_leadership_description {
                                    value
                                }
                                field_leadership_position_title
                            }
                        }
                        allNodeLeadershipBioV2(sort: {changed: DESC}) {
                            nodes {
                                drupal_internal__nid
                                title
                                changed
                                field_leadership
                                field_leadership_bio_summary
                                field_leadership_position_title
                                internal {
                                    type
                                }
                                fields {
                                    url
                                }
                                relationships {
                                    field_components {
                                        ... on paragraph__title_and_text {
                                            field_title
                                            field_body {
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        allNodeLeadershipBio(sort: {changed: DESC}) {
                            nodes{
                                drupal_internal__nid
                                changed
                                internal {
                                type
                                }
                                fields {
                                    url
                                }
                                title
                                field_last_name
                                field_leadership_position_title
                                field_hero_quote
                                field_disclaimer
                                relationships {
                                    field_components {
                                        type: __typename
                                        ... on paragraph__section_text {
                                            field_header
                                            field_text_content {
                                                value
                                            }
                                        }
                                        ... on paragraph__quote_card {
                                            field_card_quote_text
                                        }
                                        ... on paragraph__leadership_parent_articles {
                                            relationships {
                                                field_articles_and_insights {
                                                    field_title
                                                    field_article_description
                                                }
                                            }
                                        }
                                        ... on paragraph__section_video_card {
                                            field_video_card_title
                                            field_video_body_text
                                        }
                                        ... on paragraph__section_image {
                                            field_title_section
                                        }
                                    }
                                    field_limited_components {
                                        type: __typename
                                        ... on paragraph__section_about_text {
                                            field_header
                                            field_text_content {
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                `,
                    name: `pages`,
                    engine: `flexsearch`,
                    ref: `id`,
                    index: [`title`, `body`],
                    store: [
                        `id`,
                        `title`,
                        `type`,
                        `source`,
                        `body`,
                        `category`,
                        `url`,
                        `date`,
                        `changed`,
                    ],
                    normalizer: ({ data }) => {
                        let outData = [];
                        for (const nodes in data) {
                            outData = outData.concat(
                                data[nodes].nodes.map((node) => {
                                    let type = ``;
                                    const title = node.title;
                                    let source = ``;
                                    let category = ``;
                                    let body = ``;
                                    const url = node.fields?.url
                                        ? node.fields.url
                                        : `#`;
                                    const date = node.field_news_date
                                        ? node.field_news_date
                                        : ``;
                                    const changed = node.changed;
                                    if (
                                        node.internal.type ===
                                        `node__ir_news_article`
                                    ) {
                                        type = `article`;
                                        source = `ir`;
                                    } else if (
                                        node.internal.type ===
                                        `node__newsroom_article`
                                    ) {
                                        type = `article`;
                                        source = `newsroom`;
                                    }
                                    if (type === `article` && source === `ir`) {
                                        category =
                                            node.relationships
                                                .field_news_category?.name;
                                        body = `${
                                            node.field_subtitle
                                                ? node.field_subtitle
                                                : ``
                                        } ${node.field_summary}`;
                                    }
                                    if (
                                        type === `article` &&
                                        source === `newsroom`
                                    ) {
                                        category =
                                            node.relationships
                                                .field_news_category?.name;
                                        body = createArticleSearchBody(
                                            `${(
                                                (node.field_subtitle
                                                    ? node.field_subtitle
                                                    : ``) +
                                                ` ` +
                                                (node.field_summary
                                                    ? node.field_summary
                                                    : ``)
                                            ).trim()}`,
                                            node.relationships?.field_components
                                        );
                                    } else {
                                        body = node.subtitle;
                                    }
                                    if (url.includes(`leadership`)) {
                                        type = `leadership`;
                                        category = `Executive Leadership`;
                                        body =
                                            node.field_leadership_description
                                                ?.value;
                                    }
                                    if (
                                        node.internal.type ===
                                        `node__leadership_bio_v2`
                                    ) {
                                        // Iterate over each node's paragraph title & text field components
                                        // to add their title and body text to the searchable 'body' field
                                        const paragraphs = [];
                                        if (
                                            node?.relationships
                                                ?.field_components.length > 0
                                        ) {
                                            for (const component of node
                                                .relationships
                                                .field_components) {
                                                component?.field_title
                                                    ? paragraphs.push(
                                                          component?.field_title
                                                      )
                                                    : null;
                                                component?.field_body?.value
                                                    ? paragraphs.push(
                                                          component?.field_body
                                                              ?.value
                                                      )
                                                    : null;
                                            }
                                        }
                                        type = `leadership`;
                                        category = `Executive Leadership`;
                                        // separate all searchable text groups with a pipe
                                        body = stripHtmlStrings(
                                            `${
                                                node.field_leadership_bio_summary
                                            } | ${paragraphs.join(` | `)}`
                                        );
                                    }
                                    if (
                                        node.internal.type ===
                                        `node__leadership_bio`
                                    ) {
                                        // Adds the fields from the top level of the Leadership Bio content type for search
                                        const bioFields = `${node.field_last_name} | ${node.field_leadership_position_title} | ${node.field_hero_quote} | ${node.field_disclaimer}`;
                                        // Iterates over each sub component of a Leadership Bio and adds the relevant fields for search
                                        const paragraphs = [];
                                        if (
                                            node?.relationships
                                                ?.field_components.length > 0
                                        ) {
                                            for (const component of node
                                                .relationships
                                                .field_components) {
                                                // Section Text Header
                                                component.type ===
                                                    `paragraph__section_text` &&
                                                component.field_header
                                                    ? paragraphs.push(
                                                          component.field_header
                                                      )
                                                    : null;
                                                // Section Text Content
                                                component.field_text_content
                                                    ?.value
                                                    ? paragraphs.push(
                                                          component
                                                              .field_text_content
                                                              .value
                                                      )
                                                    : null;
                                                // Quote Card Text
                                                component.field_card_quote_text
                                                    ? paragraphs.push(
                                                          component.field_card_quote_text
                                                      )
                                                    : null;
                                                // Article & Insights Article Titles and Descriptions
                                                component.relationships
                                                    ?.field_articles_and_insights
                                                    ?.length > 0
                                                    ? component.relationships?.field_articles_and_insights.forEach(
                                                          (article) => {
                                                              paragraphs.push(
                                                                  article.field_title
                                                              );
                                                              paragraphs.push(
                                                                  article.field_article_description
                                                              );
                                                          }
                                                      )
                                                    : null;
                                                // Video Card Title
                                                component.field_video_card_title
                                                    ? paragraphs.push(
                                                          component.field_video_card_title
                                                      )
                                                    : null;
                                                // Video Card Body Text
                                                component.field_video_body_text
                                                    ? paragraphs.push(
                                                          component.field_video_body_text
                                                      )
                                                    : null;
                                                // Image Card Text
                                                component.field_title_section
                                                    ? paragraphs.push(
                                                          component.field_title_section
                                                      )
                                                    : null;
                                            }
                                        }
                                        if (
                                            node?.relationships
                                                ?.field_limited_components
                                        ) {
                                            const component =
                                                node.relationships
                                                    .field_limited_components;
                                            // About Section Text Header
                                            component.field_header
                                                ? paragraphs.push(
                                                      component.field_header
                                                  )
                                                : null;
                                            // About Section Text Content
                                            component?.field_text_content?.value
                                                ? paragraphs.push(
                                                      component
                                                          .field_text_content
                                                          .value
                                                  )
                                                : null;
                                        }
                                        type = `leadership`;
                                        category = `Executive Leadership`;
                                        // separate all searchable text groups with a pipe
                                        body = `${bioFields} | ${stripHtmlStrings(
                                            paragraphs.join(` | `)
                                        )}`;
                                    }
                                    if (url.includes(`board-of-directors`)) {
                                        type = `board-of-directors`;
                                        category = `Board of Directors`;
                                        body =
                                            node.field_leadership_description
                                                ?.value;
                                    }
                                    if (node.internal.type === `GenericPage`) {
                                        type = `page`;
                                        category = `Page`;
                                        body = node.body ? node.body : ``;
                                        if (url.includes(`resource-hub`)) {
                                            type = `resource-hub`;
                                            category = `Resource Hub`;
                                        }
                                    }

                                    return {
                                        id: node.drupal_internal__nid,
                                        title,
                                        type,
                                        source,
                                        category,
                                        body,
                                        url,
                                        date,
                                        changed,
                                    };
                                })
                            );
                        }
                        return outData;
                    },
                },
            },
        ],
    },
    ga: {
        id: `ga`,
        title: `Charter Policy Website`,
        siteUrl: `https://${URLS[process.env.GATSBY_SITE_NAME]?.prod[0]}`,
        endpoint: process.env.ENDPOINT_GA
            ? process.env.ENDPOINT_GA
            : `https://prod-ga.policy.charter.com/`,
        menus: [...baseMenus, `public-policy`],
        fragments: [],
        siteTitleSuffix: ` | Charter Policy`,
        robotsConfig: {
            host: `https://policy.charter.com`,
            sitemap: [
                `https://policy.charter.com/sitemap/sitemap-index.xml`,
                `https://policy.charter.com/image-sitemap.xml`,
            ],
            policy: [
                {
                    userAgent: `*`,
                    disallow: [`/search?*`],
                },
            ],
        },

        plugins: [
            {
                resolve: `gatsby-plugin-local-search`,
                options: {
                    query: `
                    {
                        allGenericPage(sort: {changed: DESC}) {
                            nodes {
                                drupal_internal__nid
                                title
                                id
                                changed
                                body
                                internal {
                                    type
                                }
                                fields {
                                    url
                                }
                                subtitle
                            }
                        }
                        allNodeArticlePolicy(sort: {changed: DESC}) {
                            nodes {
                                drupal_internal__nid
                                field_news_date(formatString: "MMMM D, YYYY")
                                title
                                id
                                changed
                                internal {
                                    type
                                }
                                body {
                                    value
                                }
                                fields {
                                    url
                                }
                                field_summary
                                field_subtitle
                                relationships {
                                    field_news_category {
                                        name
                                    }
                                    field_page_components {
                                        type: __typename
                                        ... on paragraph__article_body {
                                            field_body {
                                                value
                                            }
                                        }
                                        ... on paragraph__video {
                                            field_component_title
                                            field_caption
                                        }
                                        ... on paragraph__image {
                                            field_subtitle
                                            relationships {
                                                field_image {
                                                    field_media_image {
                                                        alt
                                                    }
                                                }
                                            }
                                        }
                                        ... on paragraph__pull_quote_article {
                                            field_subtitle
                                            field_body {
                                                value
                                            }
                                            relationships {
                                                field_image {
                                                    field_media_image {
                                                        alt
                                                    }
                                                }
                                            }
                                        }
                                        ... on paragraph__paragraph_header {
                                            field_heading
                                            field_numbering
                                            field_subtitle
                                        }
                                    }
                                }
                            }
                        }
                        allNodeLeadership(sort: {changed: DESC}) {
                            nodes {
                                drupal_internal__nid
                                title
                                id
                                changed
                                internal {
                                    type
                                }
                                fields {
                                    url
                                }
                                field_leadership
                                field_leadership_description {
                                    value
                                }
                                field_leadership_position_title
                            }
                        }
                        allNodeLeadershipBioV2(sort: {changed: DESC}) {
                            nodes {
                                drupal_internal__nid
                                title
                                changed
                                field_leadership
                                field_leadership_bio_summary
                                field_leadership_position_title
                                internal {
                                    type
                                }
                                fields {
                                    url
                                }
                                relationships {
                                    field_components {
                                        ... on paragraph__title_and_text {
                                            field_title
                                            field_body {
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                `,
                    name: `pages`,
                    engine: `flexsearch`,
                    ref: `id`,
                    index: [`title`, `body`],
                    store: [
                        `id`,
                        `title`,
                        `type`,
                        `source`,
                        `body`,
                        `category`,
                        `url`,
                        `date`,
                        `changed`,
                    ],
                    normalizer: ({ data }) => {
                        let outData = [];
                        for (const nodes in data) {
                            outData = outData.concat(
                                data[nodes].nodes.map((node) => {
                                    let type = `page`;
                                    let source = ``;
                                    let category = ``;
                                    let body = ``;
                                    const url = node.fields?.url
                                        ? node.fields.url
                                        : `#`;
                                    const date = node.field_news_date
                                        ? node.field_news_date
                                        : ``;
                                    const title = node.title;
                                    const changed = node.changed;

                                    if (
                                        node.internal.type ===
                                        `node__article_policy`
                                    ) {
                                        type = `article`;
                                        source = `newsroom`;
                                    }
                                    if (type === `article`) {
                                        category =
                                            node.relationships
                                                .field_news_category?.name;
                                        body =
                                            node?.relationships?.field_page_components?.some(
                                                (comp) =>
                                                    comp?.type ===
                                                    `paragraph__article_body`
                                            )
                                                ? // creates a searchable body for v2 articles
                                                  createArticleSearchBody(
                                                      stripHtmlStrings(
                                                          `${
                                                              (node.field_subtitle
                                                                  ? node.field_subtitle
                                                                  : ``) +
                                                              ` ` +
                                                              (node.field_summary
                                                                  ? node.field_summary
                                                                  : ``)
                                                          }`
                                                      ),
                                                      node.relationships
                                                          ?.field_page_components
                                                  )
                                                : // keeps v1 body text searchable
                                                  stripHtmlStrings(
                                                      `${
                                                          (node.field_subtitle
                                                              ? node.field_subtitle
                                                              : ``) +
                                                          ` ` +
                                                          (node.field_summary
                                                              ? node.field_summary
                                                              : ``) +
                                                          ` ` +
                                                          node.body?.value
                                                      }`
                                                  );
                                    } else {
                                        body = stripHtmlStrings(
                                            `${node.subtitle}`
                                        );
                                    }
                                    if (url.includes(`leadership`)) {
                                        type = `leadership`;
                                        category = `Executive Leadership`;
                                        body = stripHtmlStrings(
                                            `${node.field_leadership_description?.value}`
                                        );
                                    }
                                    // Currently there are no Leadership Bio V2 nodes in GA
                                    if (
                                        node.internal.type ===
                                        `node__leadership_bio_v2`
                                    ) {
                                        // Iterate over each node's paragraph title & text field components to add their title and body text to the searchable 'body' field
                                        const paragraphs = [];
                                        if (
                                            node?.relationships
                                                ?.field_components.length > 0
                                        ) {
                                            for (const component of node
                                                .relationships
                                                .field_components) {
                                                component?.field_title
                                                    ? paragraphs.push(
                                                          component?.field_title
                                                      )
                                                    : null;
                                                component?.field_body?.value
                                                    ? paragraphs.push(
                                                          component?.field_body
                                                              ?.value
                                                      )
                                                    : null;
                                            }
                                        }
                                        type = `leadership`;
                                        category = `Executive Leadership`;
                                        // separate all searchable text groups with a pipe
                                        body = stripHtmlStrings(
                                            `${
                                                node.field_leadership_bio_summary
                                            } | ${paragraphs.join(` | `)}`
                                        );
                                    }
                                    if (url.includes(`board-of-directors`)) {
                                        type = `board-of-directors`;
                                        category = `Board of Directors`;
                                        body = stripHtmlStrings(
                                            `${node.field_leadership_description?.value}`
                                        );
                                    }
                                    if (node.internal.type === `GenericPage`) {
                                        type = `page`;
                                        category = `Page`;
                                        body = stripHtmlStrings(
                                            `${node.body ? node.body : ``}`
                                        );
                                        if (url.includes(`resource-hub`)) {
                                            type = `resource-hub`;
                                            category = `Resource Hub`;
                                        }
                                    }
                                    return {
                                        id: node.drupal_internal__nid,
                                        title,
                                        type,
                                        source,
                                        category,
                                        body,
                                        url,
                                        date,
                                        changed,
                                    };
                                })
                            );
                        }
                        return outData;
                    },
                },
            },
        ],
    },
};

export const CURRENT_SITE = SITES[process.env.GATSBY_SITE_NAME as string];
